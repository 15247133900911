@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.spinner {
  @include absoluteCenter()
}

.category-view {
  @include roboto-regular();
  margin: 40px 0 40px 64px;
  padding: 32px 110px 48px 104px;
  background-color: $color-contrast;
  width: 920px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__arrow {
    margin-bottom: 24.41px;
    cursor: pointer;
    height: fit-content;
      &:hover {
        svg path{
          transition: 1s ease-in-out;
          fill: $color-test-border-hover;
      }
    }
  }

  &__article-counts {
    color: $color-grey-additional;
  }
  
  &__title {
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.5px;
    margin: 0px 0 40px 0;
    color: $color-grey-additional;
  }
}

.category-view__arrow:not(:hover) {
  transition: 1s ease-in-out;
}

@media (max-width: $mobile) {

  .category-view {
    margin: 0px;
    padding: 0;
    left: 16px;
    width: $mobile;

    &__header {
      display: inline;
    }

    &__title {
      font-size: 20px;
      width: 238px;
      position: relative;
      top: 16px;
      left: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 20px;
    }

    &__arrow {
      > svg {
        width: 24px;
        height: 24px;
      }
      position: absolute;
      top: 23px;
      left: 16px;
    }
  }
}
