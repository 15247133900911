@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.article-card {
  @include roboto-regular();
  cursor: pointer;
  position: relative;
  width: 712px;
  height: 124px;
  margin-bottom: 28px;

  &__card-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    width: fit-content;
    color: $color-grey-additional;
  }

  &__card-description {
    width: 656px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: $color-gray-text;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
  }
  
  .article-card-arrow {
    position: absolute;
    width: 25px;
    height: 30px;
    left: 690px;
    top: 50px;
  }

  &_disabled {
    pointer-events: none;
    .article-card__card-title {
      color: $color-gray-text;
    }
    .article-card__card-description {
      color: $color-light-gray;
    }
    .e-tag.Locked {
      background-color: $color-secondary;
    }
  }
}

.article-card:hover {
  border-bottom: 1.5px solid $color-test-border-hover;
  transition: 500ms ease-in-out;

  .article-card__card-title {
    transition: 500ms ease-in-out;
    color: $color-test-border-hover;
  }

  .article-card__card-description {
    transition: 500ms ease-in-out;
    color: $color-grey-additional;
  }
  .article-card-arrow path {
    transition: 500ms ease-in-out;
    fill: $color-test-border-hover;
  }
}

.article-card:not(:hover) {
  transition: 500ms ease-in-out;
  .article-card__card-title {
    transition: 500ms ease-in-out;
  }

  .article-card__card-description {
    transition: 500ms ease-in-out;
  }
  .article-card-arrow path {
    transition: 500ms ease-in-out;
  }
}


@media (max-width: $mobile) {
  .article-card {
    width: 328px;
    height: 112px;
    border-bottom: solid 1px $color-test-border;
    margin-bottom: 20px;
    padding: 16px;

    &__card-title {
      width: 288px;
      height: 20px;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    &__card-description{
      width: 288px;
      height: 36px;
      font-size: 14px;
      line-height: 18px;
    }

    .article-card-arrow {
      left: 310px;
    }
  }
}

