@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.dateOfRecall {
  padding: 0 25px 25px 25px;
  width: 500px;
  font-family: arial, sans-serif;
  &__content {
    .textArea__wrapper {
      margin-bottom: 10px;
    }
  }

  @media (max-width: $tablet) {
    width: 400px;
  }

  @media (max-width: $mobile) {
    width: 300px;
    padding: 12px;
  }

  &__status {
    padding-bottom: 5px;
    font-size: 18px;
  }

  &__status span {
    font-size: 16px;
    padding-right: 3px;
  }

  &__status span,
  &__id {
    color: $color-secondary;
  }

  &__id-button {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(space-between);
  }

  &__field {
    margin-top: 15px;
    @media (max-width: $tablet) {
      .textArea__wrapper {
        --textArea-width: 350px !important;
      }
    }
    @media (max-width: $mobile) {
      .textArea__wrapper {
        --textArea-width: 100% !important;
      }
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }

  &__comment {
    width: 100% !important;
  }

  &__submitBlock {
    @media (max-width: $mobile) {
      .e-button__wrapper {
        width: 100%;

        .dateOfRecall__send-button {
          width: 100% !important;
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 99999999 !important;
}
