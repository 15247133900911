@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.training-room-footer {
  height: 56px;
  background: $color-contrast;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0px 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}