@use '@styles/core/mixins/color' as *;

.feedback-sent {
  padding: 20px;
  background: $color-contrast;
  border: 1px solid $color-border;
  border-radius: 4px;
  display: flex;
  font-family: "Roboto";
  flex-direction: column;
  align-items: center;

  &__title {

    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $color-main;
    margin: 28px 0 8px 0;
  }

  &__text {

    color: $color-gray-text;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;

  }

}
