@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.progress-bar {
  height: 10px;
  width: 100%;
  background-color: $color-bg-grey;
  border-radius: 32px;

  &__filler {

    transition: 1s ease-in-out width;
    height: 100%;
    background-color: $color-accent;
    border-radius: 32px;

  }
}

