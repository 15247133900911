@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.spinner {
  @include absoluteCenter()
}

.article-test {

  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  font-family: Roboto;
  flex-direction: column;
  color: $color-grey-additional;

  &__frame {
    background: $color-contrast;
    padding: 24.41px 36px 40px 36px;
    border: 1px solid $color-test-border;
    border-radius: 3px;
    margin-bottom: 64px;
  }

  &__arrow {
    cursor: pointer;
  }

  &__question {
    margin: 24.41px 0 40px 0;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;

  }

  &__answer {
    min-width: 328px;
    border-radius: 4px;
    border: 1px solid $color-test-border;
    padding: 22px 20px;
    display: flex;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      background: rgba(22, 101, 216, 0.1);
      border-color: $color-test-border-hover;
    }
  }

  &__count {
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 20px;
    color: $color-grey-additional;
  }

  &__progress {
    width: 614px;
    display: flex;
    align-items: center;
    margin: 0 20px;
  }

  &__button-next {
    margin-left: 8px;
  }

}