@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.article-view {
  @include roboto-regular();
  margin: 40px 0 104px 64px ;
  width: 920px;
  background-color: $color-contrast;
  border: 1px solid $color-border;
  color: $color-grey-additional;

  &__content {
    width: 712px;
    @include flex();
    @include flexDirection(column);
    justify-content: center;
    margin-left: 104px;

    img {
      max-width: 100%;
      padding: 20px 0;
      min-width: 100%;
    }

    iframe {
      width: 712px;
      height: 400px;
    }
  }

  &__arrow-svg {
    position: relative;
    left: 100px;
    margin-top: 32px;
    width: 15px;
    fill: $color-secondary;
    cursor: pointer;
  }

  &__lesson-title {
    position: relative;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.5px;
    width:fit-content;
    height: 40px;
    margin: 22px 104px 40px;
  }

  &__footer {
    min-width: 920px;
    display: flex;
    justify-content: flex-end;
    margin-left: 64px;
  }
}
.training-room-footer {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
}

.spinner {
  @include absoluteCenter()
}

@media (max-width: $mobile) {

  .article-view {
    width: auto;
    background-color: $color-contrast;
    width: auto;
    margin: 16px;
    margin-bottom: 60px;
    border: 0;


    &__lesson-title {
      margin: 0;
      font-size: 24px;
    }

    &__content {
      width: auto;
      margin: 0;
    }

    &__arrow-svg {
      left: 0;
      bottom: 15px;
    }
  }

}

