@use '@styles/core/mixins/color' as *;

.feedback-choice {
  padding: 20px;
  background: $color-contrast;
  border: 1px solid $color-border;
  border-radius: 4px;
  display: flex;
  font-family: "Roboto";
  flex-direction: column;
  align-items: center;

   &__title {
     font-weight: 500;
     font-size: 24px;
     line-height: 28px;
     color: $color-main;
   }

  &__text {
    margin: 12px 0 27.64px 0;
    color: $color-gray-text;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  &__stars {
    margin-bottom: 47.64px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__textarea {
    width: 100%;
    height: 94px;
    border: 1px solid $color-border;
    padding: 11px 0 0 12px;
    resize: none;
    margin: 0 0 40px 0;
  }

  &__textarea::placeholder {
    font-size: 14px;
    line-height: 18px;
    color: $color-gray-text;
  }

  &__textarea:focus {
    outline: none;
  }

  &__buttons {
    display: flex;
    justify-self: flex-end;
    width: 100%
  }

  &__button-next-lesson {
    //margin: 0 16px 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
