@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.category-card {
  cursor: pointer;
  display: flex;
  padding: 4px 20px 4px 12px;
  border: $color-border 1.6px solid;
  border-radius: 4px;
  background: $color-contrast;
  margin-bottom: 20px;
  width: 920px;
  min-height: 132px;
  position: relative;

  &__photo {
    height: 120px;
    width: 120px;
  }

  &__photo img{
    height: 120px;
    width: 120px;
  }

  &__info {
    margin: 24px 0  0 28px;
    max-width: 576px;

  }

  &__card-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    color: $color-grey-additional;
    font-weight: 500;
  }

  &__card-description {
    font-size: 16px;
    line-height: 20px;
    color: $color-gray-text;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__number {
    position: absolute;
    right: 20px;
    top: 28px;
    font-size: 16px;
    color: $color-grey-additional;
    letter-spacing: 0.5px;
  }

  &_disabled {
    pointer-events: none;
    background-color: $background-gray;
    color: $color-gray-text;
    .category-card__photo {
      filter: grayscale(80%);
    }
    .category-card__card-title {
      color: $color-gray-text;
    }
  }
}

.category-card:hover {
  transition: 1s ease-in-out;
  border: $color-test-border-hover 1.6px solid;
  box-shadow: 0px 16px 12px rgba(0, 0, 0, 0.15);

  .category-card__card-title {
    transition: 1s ease-in-out;
    color: $color-test-border-hover;
    }

  .category-card__card-description {
    transition: 1s ease-in-out;
    color: $color-grey-additional;
  }
  .category-card__number {
    transition: 1s ease-in-out;
    font-weight: 500;
  }
}

.category-card:not(:hover) {
  transition: 1s ease-in-out;
  .category-card__card-title {
    transition: 1s ease-in-out;
    }
    .category-card__card-description {
      transition: 1s ease-in-out;
    }
    .category-card__number {
      transition: 1s ease-in-out;
   }
}


@media (max-width: $mobile) {

  .category-card {
    width: 328px;
    height: 352px;

    &__photo img {
      margin: 10px 10px;
    }

    &__info {
      width: 296px;
      position: absolute;
      top: 150px;
      margin: 30px 20px 0px;

    }

    &__number {
      position: absolute;
      left: 0px;
      top: 130px;
    }

    &__card-description {
      font-size: 14px;
      letter-spacing: 0.15px;
      margin: 8px 0px;
      height: 35px;
    }

    &__card-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-bottom: 0px;
      height: 48px;
      letter-spacing: 0.15px;
      font-weight: 500;
    }
  }
}
