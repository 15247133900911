@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;



.categories {
  @include roboto-regular();
  margin-left: 64px;
  @media (max-width: $mobile) {
  margin-left: 0px;
  margin-bottom: 50px;
}

  &__page-title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.5px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: $color-grey-additional;
  }

}

