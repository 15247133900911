.radio {
  display: flex;
  align-items: center;

  &__input {
    width: 16px;
    height: 16px;
  }

  &__label {
    margin-left: 15px;
    font-size: 20px;
    line-height: 24px;
  }

  &__input, &__label {
    cursor: pointer;
  }
}